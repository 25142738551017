.about-videos-main .about-videos-section-title {
  font-weight: bold;
  color: #fe8875;
}

.about-videos-main .about-video {
  height: 250px;
  border-radius: 15px;
}

.about-videos-main .about-videos-section {
  border: 1px solid #fe8875;
  box-shadow: 0 3px 6px #f370214d;
  border-radius: 5px;
}
