* {
  box-sizing: border-box;
}

.certificate {
  display: flex;
  justify-content: space-around;
  padding: 15px;
}


.certificate .Meta input {
  padding: 5px;
  width: 100%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
  border: 2px solid rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.certificate .Meta button {
  padding: 10px 15px;
  border: 2px solid #87ceeb;
  background-color: #33f3fb;
  margin: 20px 0;
  border-radius: 10px;
  cursor: pointer;
}

.certificate #downloadWrapper {
  padding: 20px;
}

.certificate #certificateWrapper {
  box-shadow: 0 0 5px #000;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.certificate #certificateWrapper img {
  display: block;
}

.certificate #certificateWrapper p {
  margin: 0;
  position: absolute;
  top: 300px;
  font-size: 40pt;
}

.buttonEdit {
  background-color: #fe8875;
  color: white;
  border: none;
  padding: 5px 32px;
  margin: 16px;
  border-radius: 7px;
}

.buttonDownload {
  background-color: #42c070;
  color: white;
  border: none;
  padding: 5px 32px;
  margin: 16px;
  border-radius: 7px;
}

.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.popup_inner {
  position: absolute;
  left: 25%;
  right: 25%;
  top: 25%;
  bottom: 25%;
  margin: auto;
  background: white;
}

@media (max-width: 728px) {
  .certificate {
    flex-direction: column;
    padding: 10px;
    overflow-y: auto; /* Enable vertical scrolling */
  }

  .certificate .Meta {
    width: 100%;
    text-align: center; /* Center align text and input/button */
  }

  .certificate #certificateWrapper {
    width: 100%; /* Ensure the wrapper takes full width */
    max-width: none; /* Remove max-width constraint */
  }

  .certificate #certificateWrapper img {
    width: 100%; /* Ensure image scales with container */
    height: auto; /* Maintain aspect ratio */
  }

  .certificate #certificateWrapper p {
    font-size: 24pt; /* Adjust font size for smaller screens */
    top: auto; /* Reset top positioning */
    position: relative; /* Reset position for responsiveness */
  }

  .buttonEdit, .buttonDownload {
    padding: 10px 20px; /* Adjust padding for better touch experience */
    margin: 10px 0; /* Adjust margin for better spacing */
  }
}
