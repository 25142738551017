.loginmainlayout{
    border-radius: 12px;
}

@media only screen and (max-width:786px){
    .loginmainlayout{
        display: block !important;
    }

    .forsignup{
        margin: 0px;
        padding: 0px;
        display: inline !important;
        justify-content: center;
        align-items: center;
        height: 100%;
    
    }
}

@media only screen and (min-width: 768px) and (max-width: 768px){
    .forsignup{
        margin: 0px;
        padding: 0px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        height: 100%;
    
    }

}