body, html {
  height: 100%;
  margin: 0;
}

.layout-container {
  /* Add any necessary styling for the Layout container if needed */
}

.login-tabs-container {
  padding: 130px 50px;
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px;
  border-radius: 30px;
  line-height: 24px;

  /* Center the container */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  /* Center text/content within the container */
  text-align: center;
}

.login-tabs {
  display: flex;
  justify-content: space-between; /* Distributes space between tabs */
}

.tab {
  padding: 20px 50px;
  border: 1px solid black;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  background-color: white;
  color: black;
  margin: 0 10px; /* Adds space between tabs */
  border-radius: 15px; /* Optional: to match the border-radius of the container */
}

.tab.active {
  background-color: rgb(254, 136, 117);
  color: white;
  border: none;
}

.tab:not(.active):hover {
  background-color: rgb(254, 136, 117);
  color: white;
  border: none;
}

/* Mobile view adjustments */
@media (max-width: 728px) {
  .login-tabs-container {
    width: 80%;
    padding: 80px 20px;
    top: 40%;
  }

  .login-tabs {
    flex-direction: column; /* Stack buttons vertically */
    align-items: center; /* Center the buttons */
  }

  .tab {
    width: 100%; /* Full width for each button */
    margin: 10px 0; /* Adjust margin for vertical stacking */
    padding: 15px 0; /* Adjust padding for better spacing */
    font-size: 18px; /* Slightly larger font for easier touch interaction */
  }
}

/* Tablet view adjustments for screens between 729px and 1024px */
@media (min-width: 729px) and (max-width: 1024px) {
  .login-tabs-container {
    width: 70%; /* Adjust the width for tablet view */
    padding: 100px 40px;
  }

  .login-tabs {
    justify-content: space-around; /* Evenly space tabs on tablets */
  }

  .tab {
    padding: 15px 40px; /* Adjust padding for better alignment */
    font-size: 16px; /* Slightly larger font for better readability on tablets */
  }
}

/* Laptop and larger screens adjustments for screens above 1024px */
@media (min-width: 1025px) {
  .login-tabs-container {
    width: 50%; /* Keep the container at 50% width */
  }

  .login-tabs {
    justify-content: space-between; /* Distribute space between the tabs */
  }

  .tab {
    padding: 20px 50px;
    margin: 0 20px; /* Increase margin between tabs for more spacing */
  }
}
