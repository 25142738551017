

.button-on-focus{
border: none !important; 
outline: none !important;
 
}

.loginBtn {
    background-color: rgb(254, 136, 117);
    padding: 8px 11px 8px 11px;
    width: 22vh;
    margin-top: 10%;
    color: white;
    border-radius: 22px;
    cursor: pointer;
    border: none;
}

.loginBtn[disabled] {
    cursor: auto;
    pointer-events: none;
    opacity: 0.4;
}


@media only screen and (max-width:768px){
    .textfield{
        width:  fit-content;
    }

    .loginBtn {
        background-color: rgb(254, 136, 117);
        padding: 8px 11px 8px 11px;
        width: 50vh;
        margin-top: 6%;
        color: white;
        border-radius: 22px;
        cursor: pointer;
        border: none;
    }
    
}

@media only screen and (min-width: 768px) and (max-width: 768px){
    .loginforTab{
        width: 100%;
        padding: 1rem;
    }
}

