.loader {
  width: 0px;
  height: 0px;
  margin: 50vh auto;
  position: absolute;
  left: 50%;
  top: -10px;
}

.loader > div {
  width: 6px;
  height: 20px;
  position: absolute;
  left: -10px;
  bottom: 15px;
  border-radius: 5px;
  -webkit-transform-origin: 10px 35px;
  -moz-transform-origin: 10px 35px;
  -ms-transform-origin: 10px 35px;
  -o-transform-origin: 10px 35px;
  transform-origin: 10px 35px;

  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);

  -webkit-animation: loader 0.8s infinite;
  -moz-animation: loader 0.8s infinite;
  animation: loader 0.8s infinite;
}
.loader > div:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);

  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.loader > div:nth-child(3) {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);

  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.loader > div:nth-child(4) {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);

  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.loader > div:nth-child(5) {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);

  -webkit-animation-delay: 0.4s;
  -moz-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.loader > div:nth-child(6) {
  -webkit-transform: rotate(225deg);
  -moz-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  -o-transform: rotate(225deg);
  transform: rotate(225deg);

  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.loader > div:nth-child(7) {
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);

  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.loader > div:nth-child(8) {
  -webkit-transform: rotate(315deg);
  -moz-transform: rotate(315deg);
  -ms-transform: rotate(315deg);
  -o-transform: rotate(315deg);
  transform: rotate(315deg);

  -webkit-animation-delay: 0.7s;
  -moz-animation-delay: 0.7s;
  animation-delay: 0.7s;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes loader {
  0% {
    background: transparent;
    left: -10px;
    -webkit-transform-origin: 10px 35px;
    -moz-transform-origin: 10px 35px;
    transform-origin: 10px 35px;
  }
  30% {
    background: rgb(254, 136, 117);
  }
  100% {
    background: transparent;
    left: 10px;
    -webkit-transform-origin: -10px 35px;
    -moz-transform-origin: -10px 35px;
    transform-origin: -10px 35px;
  }
}

@keyframes loader {
  0% {
    background: transparent;
    left: -10px;
    -webkit-transform-origin: 10px 35px;
    -moz-transform-origin: 10px 35px;
    transform-origin: 10px 35px;
  }
  30% {
    background:rgb(254, 136, 117);
  }
  100% {
    background: transparent;
    left: 10px;
    -webkit-transform-origin: -10px 35px;
    -moz-transform-origin: -10px 35px;
    transform-origin: -10px 35px;
  }
}

.MuiBackdrop-root {
  background-color: rgb(106 106 106 / 20%);
}
