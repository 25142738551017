i {
    align-self: center;
}

.contact-main-div{
    padding: 3rem;
}

@media only screen and (max-width:768px){
    .contact-main-div{
        padding: 1rem;
    }
}