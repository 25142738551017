.navbarData {
  color: black;
  font-weight: 500;
  font-size: 3vh;
  text-decoration: none;
  cursor: pointer;
}

.loginNavbardata{
  color: black;
  font-weight: 500;
  font-size: 3vh;
  text-decoration: none;
  cursor: pointer;
  flex-direction: row;
  justify-content: end;
}

.loginNavbardata:hover{
  color: #fe8875;
  font-weight: 500;
  font-size: 3vh;
  text-decoration: none;
  cursor: pointer;
  flex-direction: row;
  justify-content: end;
}
.loginPage:hover{
  color: #fe8875;
  font-weight: 500;
  font-size: 3vh;
  text-decoration: none;
  cursor: pointer;
  flex-direction: row;
  justify-content: end;
}




.navbar {
  /* justify-content: space-between; */
  padding: 0.5rem 1rem;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  transition: 0.5s;
  z-index: 1020;
}
.navbarData:hover {
  color: #fe8875;
  text-decoration: none;
}

.ActivitySection {
  background: #fe8875;
}

.VideoDesc,
.ActivityDesc,
.VideoSectionTitle,
.ActivitySectionTitle {
  color: white;
}

.AboutTitle {
  font-weight: bold;
  font-size: 5vh;
  color: #fe8875;
}

@media only screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
  }

  .form-inline {
    width: 100%;
    margin-top: 1rem;
  }

  .navbarData {
    margin-bottom: 1rem;
  }
}