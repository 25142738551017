.radioalignment{
    display: flex;
}
@media only screen and (max-width:768px){
    .radioalignment{
        display: block;
    }

    .registerbutton{
        width: 65vh !important;
    }
}