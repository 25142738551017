
.active-tab {
    background: #ff8776;
    color: white;
    cursor: pointer;
}

.inactive-tab {
    background: white;
    color: black;
    border: 1px solid #ff8776;
    cursor: pointer;
}

.add-resrc-main-section .add-resrc-main-subsection{
 padding-left: 0px !important;
 padding-right: 0px !important;
 margin: 10px  !important;
}